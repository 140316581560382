import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppLanguageProvider } from "Context/AppLanguage/AppLanguageProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import { makeMsalConfig } from "Context/UserAuth/msal/makeMsalConfig";
import { ErrorBoundary } from "Components/ErrorBoundary";

import "I18n/i18n";
import { Suspense } from "react";
import { SplashScreen } from "Components/SplashScreen";

const msalInstance = new PublicClientApplication(makeMsalConfig());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <Suspense fallback={<SplashScreen />}>
      <BrowserRouter>
        <AppLanguageProvider>
          <App pca={msalInstance} />
        </AppLanguageProvider>
      </BrowserRouter>
    </Suspense>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
