import { Route, Routes } from "react-router-dom";
import {
  ClientPortalLazy,
  PageNotFoundLazy,
  RootLazy,
  TestsLazy,
  UserLazy,
} from "Apps";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/client-portal/*" element={<ClientPortalLazy />} />
      <Route path="/user" element={<UserLazy />} />
      <Route path="/tests/*" element={<TestsLazy />} />
      <Route path="/" element={<RootLazy />} />
      {/* fallback path in case none above it match */}
      <Route path="*" element={<PageNotFoundLazy />} />
    </Routes>
  );
}
