export const resources = {
  common: {
    dev: () => import(/* webpackChunkName: "i18n-translations-common-dev" */ "I18n/translations/common/dev.json"),
    en: () => import(/* webpackChunkName: "i18n-translations-common-en" */ "I18n/translations/common/dev.json"),
  },
  components: {
    dev: () => import(/* webpackChunkName: "i18n-translations-components-dev" */ "I18n/translations/components/dev.json"),
    en: () => import(/* webpackChunkName: "i18n-translations-components-en" */ "I18n/translations/components/dev.json"),
  },
  layout: {
    dev: () => import(/* webpackChunkName: "i18n-translations-layout-dev" */ "I18n/translations/layout/dev.json"),
    en: () => import(/* webpackChunkName: "i18n-translations-layout-en" */ "I18n/translations/layout/dev.json"),
  },
  "apps.clientPortal": {
    dev: () => import(/* webpackChunkName: "i18n-translations-appClientPortal-dev" */ "Apps/ClientPortal/translations/dev.json"),
    en: () => import(/* webpackChunkName: "i18n-translations-appClientPortal-en" */ "Apps/ClientPortal/translations/dev.json"),
  },
  "apps.user": {
    dev: () => import(/* webpackChunkName: "i18n-translations-appUser-dev" */ "Apps/User/translations/dev.json"),
    en: () => import(/* webpackChunkName: "i18n-translations-appUser-en" */ "Apps/User/translations/dev.json"),
  },
};
