import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DEFAULT_LANGUAGE } from "AppConfig";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} AppLanguageContext
 * @property {string} lang - Get current language
 * @property {function} setLang - Set the language
 */

export const AppLanguageContext = createContext(null);

/**
 * @returns {AppLanguageContext} AppLanguageContext
 */
export const useAppLanguageContext = () => useContext(AppLanguageContext);

export function AppLanguageProvider({ children }) {

  const { i18n } = useTranslation();
  const [ searchParams ] = useSearchParams();

  const paramLang = searchParams.get("lang");

  const [ lang, setLang ] = useState(paramLang ?? DEFAULT_LANGUAGE);

  useEffect(() => {
    console.log("App language change", lang);

    i18n.changeLanguage(lang);

  }, [ lang ]);

  return (
    <AppLanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </AppLanguageContext.Provider>
  );
}

AppLanguageProvider.propTypes = {
  children: PropTypes.node,
};
