import "./App.css";
import AppRoutes from "AppRoutes";
import PropTypes from "prop-types";
import { MsalProvider } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "Context/UserAuth/msal/CustomNavigationClient";
import { ThemeProvider } from "@mui/material";
import theme from "AppTheme";

function App({ pca }) {

  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={pca}>
        <AppRoutes />
      </MsalProvider>
    </ThemeProvider>
  );
}

App.propTypes = {
  pca: PropTypes.object,
};

export default App;
