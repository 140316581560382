import {
  ADB2C_INSTANCE, ADB2C_POLICY, ADB2C_TENANT, ADB2C_REDIRECT_URI, ADB2C_APPLICATION_ID,
} from "AppConfig";

export function makeMsalConfig() {
  return {
    auth: {
      clientId: ADB2C_APPLICATION_ID,
      redirectUri: ADB2C_REDIRECT_URI,
      postLogoutRedirectUri: ADB2C_REDIRECT_URI,
      authority: `https://${ADB2C_INSTANCE}/${ADB2C_TENANT}/${ADB2C_POLICY}`,
      knownAuthorities: [ ADB2C_INSTANCE ],
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  };
}
