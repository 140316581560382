/**
 * ⚠️ ☢️ for the ENV to be accessible inside react app, it must use prefix REACT_APP_ ☢️ ⚠️
 */

export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE;

// ADB2C settings
export const ADB2C_INSTANCE = process.env.REACT_APP_ADB2C_INSTANCE;
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT;
export const ADB2C_SCOPE_DOMAIN = process.env.REACT_APP_ADB2C_SCOPE_DOMAIN;
export const ADB2C_APPLICATION_ID = process.env.REACT_APP_ADB2C_APPLICATION_ID;
export const ADB2C_POLICY = process.env.REACT_APP_ADB2C_POLICY;
export const ADB2C_REDIRECT_URI = process.env.REACT_APP_ADB2C_REDIRECT_URI;
export const ADB2C_SCOPES = [ `https://${ADB2C_SCOPE_DOMAIN}/${ADB2C_APPLICATION_ID}/general` ];

// API connection
export const API_BASE_URI = process.env.REACT_APP_API_BASE_URI;
export const API_ENDPOINT_PREFIX = process.env.REACT_APP_API_ENDPOINT_PREFIX;
export const API_SUBSCRIPTION_KEY = process.env.REACT_APP_API_SUBSCRIPTION_KEY;

/**
 * Feature toggles
 *
 * The default state of each feature toggle is the missing/empty state,
 * only when the toggle has a value "yes", should it come to effect
 *
 * With regards to that definition, choose appropriate names that point to the feature,
 * e.g. "SHOW_LANGUAGE_OPTIONS", "ENABLE_TESTS_PAGE", so it's clear what is the default behavior.
 */
export const ENABLE_TESTS_PAGE = process.env.REACT_APP_ENABLE_TESTS_PAGE;
