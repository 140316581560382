import { resources } from "I18n/resources";

const Resources = {
  type: "backend",
  init: () => null,
  read: (language, namespace, callback) => {

    const selected = resources[namespace];
    const loader = (selected ?? {})[language];

    if (typeof loader !== "function") {
      const err = new Error(`Undefined translation resource for namespace ${namespace}, language ${language}.`);
      console.warn(err);
      return callback(err, false);
    }

    return loader().then((module) => callback(null, module.default));
  },
};

export default Resources;
