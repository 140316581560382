import { NavigationClient } from "@azure/msal-browser";

/**
 * Should improve performance,
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
 *
 * 👉 taken from MSAL react implementation sample
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/3b621aec4553aead9ffab324c113afb6067b7fbd/samples/msal-react-samples/react-router-sample/src/utils/NavigationClient.js
 */

/**
 * Extending the default NavigationClient allows you to overwrite just navigateInternal while continuing to use the default navigateExternal function
 * If you would like to overwrite both you can implement INavigationClient directly instead
 */
export class CustomNavigationClient extends NavigationClient {
  constructor(navigate) {
    super();
    // Passed in from useNavigate hook provided by react-router-dom;
    this.navigate = navigate;
  }

  // This function will be called anytime msal needs to navigate from one page in your application to another
  navigateInternal(url, options) {
    // url will be absolute, you will need to parse out the relative path to provide to the history API
    const relativePath = url.replace(window.location.origin, "");
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath, { replace: false });
    }

    return false;
  }
}
