import { createTheme } from "@mui/material";

export default createTheme({
  components: {
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          // textTransform: "none",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 16,
      },
    },
  },
});
