import { Box, CircularProgress, Typography, Alert } from "@mui/material";
import { styled } from "@mui/material";
import PropTypes from "prop-types";

const DivRoot = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  left: 0,
  padding: 3,
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 2000,
});

export function SplashScreen({ loadingText, error, logo }) {

  if (error) {
    console.error("SplashScreen error", error);
  }

  return (
    <DivRoot>
      {logo && (
        <Box
          display="flex"
          justifyContent="center"
          mb={6}
        >
          {logo}
        </Box>
      )}

      { loadingText && (
        <Typography variant="body2" component="p" sx={{ mb: 3 }}>
          {loadingText}
        </Typography>
      )}

      { error ? (
        <Alert severity="error">
          <Typography variant="body2" component="p">
            {/* TODO: translation */}
            Whoops, something went wrong
          </Typography>
        </Alert>
      ) : (
        <CircularProgress />
      )}
    </DivRoot>
  );
}

SplashScreen.propTypes = {
  loadingText: PropTypes.node,
  error: PropTypes.any,
  logo: PropTypes.node,
};
