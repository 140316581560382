import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextChainedBackend from "i18next-chained-backend";
import Resources from "./backends/Resources";

// for all options read: https://www.i18next.com/overview/configuration-options
const options = {
  debug: false,
  defaultNS: "common",
  fallbackLng: [],
  // when translation is missing, show full key including the namespace
  appendNamespaceToMissingKey: true,
  // prefixes the namespace to the returned key when using lng: 'cimode'
  appendNamespaceToCIMode: true,
  interpolation: {
    // not needed for react as it escapes by default
    escapeValue: false,
  },
  load: "currentOnly",
  // load only bare minimum, each app has to load it's translations
  ns: [ "common" ],
  react: {
    useSuspense: true,
  },
  backend: {
    backends: [
      Resources,
    ],
    backendOptions: [],
  },
};

i18n.createInstance()
  .use(initReactI18next)
  .use(I18NextChainedBackend)
  .init(options);
